import Job from "../../../apis/Job";

/**
 * Get all Jobs
 * 
 * @param {*} filter : Object of key/values
 * @returns Promise of Jobs
 */
 export const queryJobsPromise = (store, filter) => {
  let queryParts = [];
  for (var p in filter) {
    if (Object.prototype.hasOwnProperty.call(filter, p)) {
      queryParts.push(encodeURIComponent(p) + "=" + encodeURIComponent(filter[p]));
    }
  }
  const params = queryParts.join('&');

  return Job.all(params);
}


/**
 * Fetch an Job
 * 
 * @param {*} id : Job ID
 * @returns Job
 */
export const getJobPromise = (store, id) => {
  return Job.fetch(id);
}


/**
 * Cancel an Job
 * 
 * @param {*} id : Job ID
 * @returns Job
 */
export const cancelJobPromise = (store, id) => {
  return Job.cancel(id);
}


/**
 * Pause / Unpause
 * 
 * @param {*} id : Job ID
 * @returns Job
 */
export const customerPauseJobPromise = (store, {id, status}) => {
  return Job.flag(id, {flag: 'customer_paused', status: status}); // Kanske 'customer_paused' om vi lägger till den i api't
}


/**
 * Restart an Order
 * 
 * @param {*} id : Order ID
 * @returns Order
 */
export const restartJobPromise = (store, id) => {
  return Job.restart(id);
}


/**
 * Address update
 * 
 * @param {*} order_id : Order ID
 * @returns Order promise
 */
export const updateJobAddressPromise = (store, {id, data}) => {
  return Job.updateAddress(id, data);
}


/**
 * Express update
 * 
 * @param {*} id Printerapi job id
 * @param { production: Number, delivery: Number } data At least one needed
 * @returns Order promise
 */
export const updateJobExpressPromise = (store, {id, data}) => {
  return Job.express(id, data);
}


/**
 * Cancel an Job
 * 
 * @param {*} id : Job ID
 * @returns Job
 */
 export const updateReturnJobPromise = (store, {id, data}) => {
  return Job.updateReturn(id, data);
 }

// export const updateReturnCommentPromise = (store, { id, data }) => {
//   return Job.updateReturnComment(id, data);
// }



/**
 * Order flags
 */
export const addJobFlagsPromise = (store, {id, data}) => {
  return Job.addJobFlags(id, data);
}

export const removeJobFlagsPromise = (store, {id, data}) => {
  return Job.removeJobFlags(id, data);
}
