import Api from "./Api";

const END_POINT = '/job';

export default {
  all(query) {
    return Api.get(`${END_POINT}?${query}`);
  },

  fetch(id) {
    return Api.get(`${END_POINT}/${id}`);
  },

  cancel(id) {
    return Api.get(`${END_POINT}/${id}/cancel`);
  },

  flag(id, data) {
    return Api.post(`${END_POINT}/${id}/flag`, data);
  },

  restart(id) {
    return Api.get(`${END_POINT}/${id}/restart`);
  },

  updateAddress(id, data) {
    return Api.post(`${END_POINT}/${id}/update/address`, data);
  },

  express(id, data) {
    return Api.post(`${END_POINT}/${id}/update/express`, data);
  },

  //claim
  claim(id, data) {
    console.log("claim data",data);
    //console.log("claim got sent", id);
    return Api.post(`${END_POINT}/${id}/claim`, data);
  },

  updateReturn(id, data) {
    return Api.post(`${END_POINT}/${id}/updatereturn`, data);
  },

  updateReturnComment(id, data) {
    return Api.post(`${END_POINT}/${id}/update/return-comment`, data);
  },

  // Flags
  addJobFlags(id, data) {
    return Api.post(`${END_POINT}/${id}/add-flags`, data);
  },

  removeJobFlags(id, data) {
    return Api.post(`${END_POINT}/${id}/remove-flags`, data);
  }

}
